import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminLayoutComponent } from './admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './auth-layout/auth-layout.component';
import { HeaderComponent } from '../components/shared/header/header.component';
import { RouterModule } from '@angular/router';
import { PrimengModule } from '../primeng/primeng.module';
import { FormsModule } from '@angular/forms';
import { SidebarModule } from 'primeng/sidebar';
import { SlideMenuModule } from 'primeng/slidemenu';
import { ButtonModule } from 'primeng/button';
import { OverlayPanelModule } from 'primeng/overlaypanel';


@NgModule({
  declarations: [
    AdminLayoutComponent, 
    AuthLayoutComponent, 
    HeaderComponent
  ],
  imports: [
    RouterModule,
    //PrimengModule,
    SidebarModule,
    SlideMenuModule,
    ButtonModule,
    FormsModule,
    CommonModule,
    OverlayPanelModule
  ]
})
export class LayoutsModule { }
