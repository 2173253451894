import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Usuario } from 'src/app/models/usuario';
import { MessageService } from 'primeng/api';
import { LoginService } from 'src/app/services/login.service';
import { VwUsuarioRazon } from 'src/app/models/vwUsuarioRazon';
import { BlockUIModule } from 'primeng/blockui';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.css'],
  providers: [MessageService],
})
export class MyProfileComponent implements OnInit {
  objusuario: Usuario = new Usuario();
  passwordNueva: string = '';
  passwordConfirm: string = '';
  objvWusuarioRazon: VwUsuarioRazon = new VwUsuarioRazon();
  flg_activar2fa: boolean = false;
  qrCode: string = ""
  codigoVerificacion: string = "";
  constructor(private usuarioService: UsuarioService, private messageService: MessageService, private loginservice: LoginService, public blokeo: BlockUIModule,) { }

  ngOnInit(): void {
    this.objvWusuarioRazon = this.loginservice.getUser();
    this.obtenerUsuario();
    this.blokeo = false

  }

  obtenerUsuario() {
    this.blokeo = true;
    this.usuarioService.getById(this.objvWusuarioRazon.id_usuario).subscribe((data: Usuario) => {
      this.objusuario = data;
      this.blokeo = false;
    });
  }

  guardarUsuario() {

    this.blokeo = true;
    if (this.passwordNueva && this.passwordNueva === this.passwordConfirm) {
      this.objusuario.txt_pass_usuario = this.passwordNueva;
    }

    var fch_usu_modi = new Date();
    let mes_emision: number = fch_usu_modi.getMonth();
    mes_emision = mes_emision + 1;
    let dia_emision: number = fch_usu_modi.getDate();


    this.objusuario.id_usu_modi = this.objvWusuarioRazon.id_usuario;
    this.objusuario.fch_usu_modi = fch_usu_modi.getFullYear() +
      "-" +
      ("0000" + mes_emision).slice(-2) +
      "-" +
      ("0000" + dia_emision).slice(-2);

      if(this.objusuario.flg_2fa == false){
        this.objusuario.secret_key_2fa = "";
      }

    this.usuarioService.update(this.objusuario).subscribe(data => {
      this.passwordNueva = "";
      this.passwordConfirm = "";
      this.obtenerUsuario();
      this.qrCode = ""
      this.messageService.add({
        severity: 'success',
        summary: 'Perfil Actualizado',
        detail: 'Los datos han sido guardados correctamente.',
      });
    });

  }

  click2fa() {
    if (this.objusuario.flg_2fa) {
      this.blokeo = true;
      this.loginservice.generateSecret(this.objvWusuarioRazon.cod_usuario, this.objvWusuarioRazon.id_usuario).subscribe(qrCode => {
        this.blokeo = false;
        this.qrCode = qrCode;
        this.flg_activar2fa = true;

      });
    }

  }

  activar2fa() {

    if(this.codigoVerificacion.length != 6){
      this.messageService.add({
        severity: 'error',
        summary: 'Activación de la autenticación de dos pasos',
        detail: 'El código debe ser de 6 dígitos',
      });
      return false;
    }

    this.blokeo = true;
    this.loginservice.verifyCode(this.codigoVerificacion, this.objvWusuarioRazon.id_usuario).subscribe(isValid => {
      if (isValid) {
        this.objusuario.flg_2fa = true;
        var fch_usu_modi = new Date();
        let mes_emision: number = fch_usu_modi.getMonth();
        mes_emision = mes_emision + 1;
        let dia_emision: number = fch_usu_modi.getDate();
    
    
        this.objusuario.id_usu_modi = this.objvWusuarioRazon.id_usuario;
        this.objusuario.fch_usu_modi = fch_usu_modi.getFullYear() +
          "-" +
          ("0000" + mes_emision).slice(-2) +
          "-" +
          ("0000" + dia_emision).slice(-2);

        this.usuarioService.update(this.objusuario).subscribe(data => {
          this.blokeo = false;
          this.qrCode = "";
          this.flg_activar2fa = false;
          this.passwordNueva = "";
          this.passwordConfirm = "";
          this.codigoVerificacion = "";
          this.obtenerUsuario();
          this.messageService.add({
            severity: 'success',
            summary: 'Activación de la autenticación de dos pasos',
            detail: 'Se activo correctamente',
          });
        });
      } else {
        this.blokeo = false;
        this.messageService.add({
          severity: 'error',
          summary: 'Activación de la autenticación de dos pasos',
          detail: 'Codigo Incorrecto',
        });
      }
    });
  }

  Cancelaractivar2fa(){
    this.flg_activar2fa = false;
    this.objusuario.flg_2fa = false;
  }
}
