import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MantParametrosComponent } from './views/mant-parametros/mant-parametros.component';
import { MantPlanesComponent } from './views/mant-planes/mant-planes.component';
import { MantProveedorComponent } from './views/mant-proveedor/mant-proveedor.component';
import { MantUsuarioComponent } from './views/mant-usuario/mant-usuario.component';
import { MantPerfilComponent } from './views/mant-perfil/mant-perfil.component';
import { MantOpcionComponent } from './views/mant-opcion/mant-opcion.component';
import { MantRznsclusuarioComponent } from './views/mant-rznsclusuario/mant-rznsclusuario.component';
import { MantOpcionperfilComponent } from './views/mant-opcionperfil/mant-opcionperfil.component';
import { MantActualizacionAppComponent } from './views/mant-actualizacion-app/mant-actualizacion-app.component';
import { MantUsuarioRazonSocialComponent } from './views/mant-usuario-razon-social/mant-usuario-razon-social.component';
import { MantNewContrasenaComponent } from './seguridad/mant-new-contrasena/mant-new-contrasena.component';
import { AuthGaurd } from './services/auth.gaurd';

/*

const routes: Routes = [
  {path: '',component: MantContribuyenteComponent},
  {path: 'mant-parametros', component: MantParametrosComponent,canActivate: [AuthGaurd]},
  {path: 'mant-planes', component: MantPlanesComponent,canActivate: [AuthGaurd]},
  {path: 'mant-proveedor', component: MantProveedorComponent,canActivate: [AuthGaurd]},
  {path: 'mant-contribuyente', component: MantContribuyenteComponent,canActivate: [AuthGaurd]},
  {path: 'mant-usuario', component: MantUsuarioComponent,canActivate: [AuthGaurd]},
  {path: 'mant-perfil', component: MantPerfilComponent,canActivate: [AuthGaurd]},
  {path: 'mant-opcionperfiles', component: MantOpcionperfilComponent,canActivate: [AuthGaurd]},
  {path: 'mant-opcion', component: MantOpcionComponent,canActivate: [AuthGaurd]},
  {path: 'mant-rznsclusuario', component: MantRznsclusuarioComponent,canActivate: [AuthGaurd]},
  {path: 'mant-versionapp', component: MantActualizacionAppComponent,canActivate: [AuthGaurd]},
  {path: 'mant-usuariorazonsocial', component: MantUsuarioRazonSocialComponent,canActivate: [AuthGaurd]},
  { path: 'seguridad/newcontrasena', component: MantNewContrasenaComponent ,canActivate: [AuthGaurd]},
  { path: '**', redirectTo: '' }*/

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MantParametroProveedorComponent } from './views/mant-parametro-proveedor/mant-parametro-proveedor.component';
import { MantTipoContactoComponent } from './views/mant-tipo-contacto/mant-tipo-contacto.component';
import { MantTipobajaComponent } from './views/mant-tipobaja/mant-tipobaja.component';
import { MyProfileComponent } from './components/user/my-profile/my-profile.component';

const adminRoutes: Routes = [
  //{path: '',component: MantContribuyenteComponent},
  { path: 'mant-parametros', component: MantParametrosComponent, canActivate: [AuthGaurd] },
  { path: 'mant-planes', component: MantPlanesComponent, canActivate: [AuthGaurd] },
  { path: 'mant-proveedor', component: MantProveedorComponent, canActivate: [AuthGaurd] },
  {
    path: '',
    loadChildren: () => import('./views/contribuyente/contribuyente.module').then(m => m.ContribuyenteModule)
  },
  { path: 'mant-usuario', component: MantUsuarioComponent, canActivate: [AuthGaurd] },
  { path: 'mant-perfil', component: MantPerfilComponent, canActivate: [AuthGaurd] },
  { path: 'mant-opcionperfiles', component: MantOpcionperfilComponent, canActivate: [AuthGaurd] },
  { path: 'mant-opcion', component: MantOpcionComponent, canActivate: [AuthGaurd] },
  { path: 'mant-rznsclusuario', component: MantRznsclusuarioComponent, canActivate: [AuthGaurd] },
  { path: 'mant-versionapp', component: MantActualizacionAppComponent, canActivate: [AuthGaurd] },
  { path: 'mant-usuariorazonsocial', component: MantUsuarioRazonSocialComponent, canActivate: [AuthGaurd] },
  { path: 'parametro-proveedor', component: MantParametroProveedorComponent, canActivate: [AuthGaurd] },
  { path: 'seguridad/newcontrasena', component: MantNewContrasenaComponent, canActivate: [AuthGaurd] },
  { path: 'mant-tipocontacto', component: MantTipoContactoComponent, canActivate: [AuthGaurd] },
  { path: 'mant-tipobaja', component: MantTipobajaComponent, canActivate: [AuthGaurd] },
  { path: 'my-profile', component: MyProfileComponent, canActivate: [AuthGaurd]},
];

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  }, 
  {
    path: '',
    component: AuthLayoutComponent,
    //canActivate: [AuthGaurd],
    children: [
      {
        path: '',
        loadChildren: () => import('./views/auth/auth.module').then(m => m.AuthModule)
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    //canActivate: [AuthGuard],
    children: adminRoutes
  },
  {
    path: '**',
    redirectTo: ''
  }

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
