export class Planes {
    id_planes:number;
    descripcion_plan:string;
    descripcion_corta:string;
    estado:number;
    precio:number;
    cantidad_max_cpe:number;
    cant_usu:number;
    cant_anexos:number;
    cod_plan_mifact: string;
}